import React, { Component } from "react";
import {Col, ControlLabel, Form, FormGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { startUserProps, editUserDetails, startEditUser  } from '../../actions/userProps';
import firebase from "firebase";



class AccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayName: props.userProps ? props.userProps.displayName : '',      
      bio: props.userProps ? props.userProps.bio : '',
      error: '', 
    }
  }
  state = {
    displayName: '',
    bio: '',
    error: ''
  }

  onBioChange = (e) => {
    const bio = e.target.value;

    if (bio && bio.length > 151) {
      this.setState({error: 'Maximum of 150 characters'})
    }
    this.setState(() => ({ bio }));
  }

  onSubmit = () => {

    this.setState(() => ({ error: '' }))

    let updates ={
      displayName: this.state.displayName,
      bio: this.state.bio,
    }
    const { displayName } = this.props.userProps
    firebase.database().ref(`users/${displayName}/`).update(updates)
  }

  render() {
    return (
      <div style={styles.container} onSubmit={this.onSubmit}>
      <Form horizontal>
  
      <FormGroup>
        <Col sm={12}>
        <p><strong>Bio</strong></p>
        <FormControl
          placeholder={'Bio'}
          value={this.state.bio}
          onChange={this.onBioChange}
          style={{backgroundColor: '#EEE'}}
        />
        {this.state.error && <p>{this.state.error}</p>}
        </Col>
        
      </FormGroup>
      
        <Button style={styles.submitUpdatesButton} onClick={this.onSubmit}>
          <p style={styles.submitButtonText}>Update Account</p>
        </Button>

      </Form>
    </div >
    )
  }
}




const mapStateToProps = state => ({
  userProps: state.userProps
});

const mapDispatchToProps = dispatch => ({
  editUserDetails: () => dispatch(editUserDetails()),
  startUserProps: () => dispatch(startUserProps()),
  startEditUser: (update) => dispatch(startEditUser(update))
});


export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);


const styles = {
  container: {
    padding: 16,
  },
  privateInfoContainer: {
    paddingTop: 20,
    paddingLeft: 16
  },
  privateInfoText:{
    color: '#9E9E9E',
    fontWeight: 'bold'
  },
  userAccountTextInput: {
    flexDirection: 'row',
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: '#FFFFFF',
    height: 40,
    flex: 1,
    borderColor: '#CCCCCC',
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    width: 90,
    paddingTop: 20,
    fontWeight: 'bold'
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: '#304FFE',
    padding: 10,
    margin: 10,
    backgroundColor: '#304FFE',
    height: 40,
  },
  submitButtonText: {
    color: '#ffffff',
    textAlign: 'center'
  },
}
