const getFamily = (familiessArr, { text }) => {
  return familiessArr.filter((family) => {
    const textMatch = family?.family_name
      .toLowerCase()
      .includes(text?.toLowerCase());
    return textMatch;
  });
};

export default getFamily;
