import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import session from "../reducers/session";
import authReducer from "../reducers/auth";
import user from "../reducers/user";
import userProps from "../reducers/userProps";
import lessons from "../reducers/lessons";
import families from "../reducers/families";
import filtersText from "../reducers/filtersText";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      sessionState: session,
      userState: user,
      auth: authReducer,
      user,
      userProps,
      lessons,
      families,
      filtersText,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
