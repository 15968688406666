import firebase from "firebase";
import database from "../firebase/firebase";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const EDIT_USER = "EDIT_USER";

// FETCH_USER CURRENTLY LOGGED IN
export const getUser = (payload) => ({
  type: "FETCH_USER",
  payload,
});

export const startUserProps = (user_id) => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    return database
      .ref(`users/${user_id.displayName}`)
      .once("value")
      .then((snapshot) => {
        const payload = snapshot.val();
        dispatch(getUser(payload));
      });
  };
};

// EDIT_USER_PROPS
export function editUserDetails() {
  return (dispatch) => {
    firebase.auth().onAuthStateChanged((user) => {
      firebase
        .database()
        .ref(`users/${user.displayName}/`)
        .on("value", (snapshot) => {
          let payload = snapshot.val();

          dispatch({
            type: FETCH_USER_DETAILS,
            payload,
          });
        });
    });
  };
}

// EDIT_LIST
export const editUser = (updates) => ({
  type: EDIT_USER,
  updates,
});

export const startEditUser = (updates) => {
  firebase.auth().onAuthStateChanged((user) => {
    return (dispatch) => {
      firebase
        .database()
        .ref(`users/${user.displayName}/`)
        .update(updates)
        .then(() => {
          dispatch(editUser(updates));
        });
    };
  });
};
