import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Layout from "../layout/Layout";
import firebase from "firebase";
import { auth, db } from "../../firebase";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import * as routes from "../../constants/routes";
const defaultImage =
  "https://firebasestorage.googleapis.com/v0/b/tierly-712dc.appspot.com/o/profile_images%2Fdefault_avatar.jpg?alt=media&token=2f96b498-bedf-410e-8558-08c3983ac0ee";

const CreateUserAccount = ({ history }) => (
  <div className="login-wrapper">
    <Layout>
      <div>
        <div xsHidden md={3} />
        <div md={6} className="login-modal">
          <h3 className="login-text">Sign up for an account</h3>
          <div className="login-well">
            <SignUpForm history={history} />
          </div>
        </div>
      </div>
    </Layout>
  </div>
);

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  name: "",
  password: "",
  photoURL: "",
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      displayName: "",
      usernames: [],
      usernameError: "",
      emailError: "",
      passwordError: "",
      error: "",
      email: "",
    };
  }
  onSubmit = (event) => {
    const { email, name, displayName, password, photoURL } = this.state;

    const { history } = this.props;

    auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        db.doCreateUser(
          authUser.uid,
          displayName.toLowerCase(),
          name,
          email,
          photoURL
        )
          .then(() => {
            firebase.auth().currentUser.updateProfile({
              displayName,
              photoURL: defaultImage,
            });

            const uid = firebase.auth().currentUser.uid;

            firebase
              .database()
              .ref(`users/${displayName}`)
              .set({
                verified: false,
                displayName,
                email,
                uid,
                admin: false,
                photoURL: defaultImage,
                bio: "",
                lessons: "",
                locations: "",
                reviews: "",
                students: "",
                teacher_name: "",
                following: 0,
                followers: 0,
              })
              .catch((error) => {
                this.setState({ error: error.message });
              });
          })
          .then(() => {
            const uid = firebase.auth().currentUser.uid;
            const followerBodySetup = true;

            const usernameSetup = {
              displayName: displayName,
              photoURL: photoURL,
              uid: uid,
            };

            let updates = {};

            // updates[`following/${uid}/` + uid] = followerBodySetup;
            // updates[`followers/${uid}/` + uid] = followerBodySetup;
            updates[`usernames/${displayName}/`] = usernameSetup;

            return firebase.database().ref().update(updates);
          })
          .then(() => {
            const usernameSetup = true;
            let updates = {};
            updates[`usernames/${displayName}/`] = usernameSetup;
            return firebase.database().ref().update(updates);
          })
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }));
            // history.push(routes.DASHBOARD);
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });

    event.preventDefault();
  };

  onEmailChange = (event) => {
    const email = event.target.value;
    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({ emailError: "Please provide valid email" });
    } else {
      this.setState({ emailError: "" });
    }
    this.setState({ email });
  };

  displayNameChecker = () => {
    const displayName = this.state.displayName
      .replace(/\s/g, "")
      .toLowerCase()
      .trim();

    if (displayName !== "") {
      firebase
        .database()
        .ref()
        .child("users")
        .orderByChild("displayName")
        .equalTo(displayName)
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            this.setState({ usernameError: "Sorry username is taken =(" });
          } else {
            this.setState({ usernameError: "Username is available!" });
          }
        });
    }
    this.setState({ usernameError: "" });
  };

  onDisplayNameChange = (event) => {
    const displayName = event.target.value.toLowerCase().trim();
    displayName.replace(/\s/g, "");

    if (displayName && displayName.length < 3) {
      this.setState({ usernameError: "Minimum of 3 characters" });
    } else if (displayName && displayName.length > 16) {
      this.setState({ usernameError: "Maximum of 16 characters" });
    } else if (displayName && !/^[a-zA-Z0-9_]*$/i.test(displayName)) {
      this.setState({
        usernameError:
          "Please provide username with no special characters or spaces!",
      });
    } else {
      this.setState({ usernameError: "" });
    }
    this.setState({ displayName });
  };

  onPasswordChange = (event) => {
    const password = event.target.value;
    if (password && password.length <= 6) {
      this.setState({ passwordError: "Minimum 6 characters" });
    } else if (password && password.length > 6) {
      this.setState({ passwordError: "" });
    }
    this.setState({ password });
  };

  render() {
    const { email, displayName, password, error, usernameError, emailError } =
      this.state;

    const isInvalid =
      email === "" ||
      displayName === "" ||
      password === "" ||
      usernameError !== "";

    console.log(error, "ERROR");
    return (
      <FormGroup bsSize="large">
        <form onSubmit={this.onSubmit}>
          <FormGroup bsSize="large">
            <FormControl
              style={{ backgroundColor: "#EEE" }}
              value={displayName}
              onChange={this.onDisplayNameChange}
              // onChangeText={(displayName) => this.setState({displayName: displayName.replace(/\s/g, '')})}
              onBlur={this.displayNameChecker}
              type="text"
              placeholder="Username"
            />
            {this.state.usernameError && (
              <p style={{ marginTop: 4 }}>{this.state.usernameError}</p>
            )}
          </FormGroup>

          <FormGroup bsSize="large">
            <FormControl
              style={{ backgroundColor: "#EEE" }}
              value={email}
              onChange={this.onEmailChange}
              type="text"
              placeholder="Email Address"
            />
            {error && <p>{error}</p>}
            {this.state.emailError && <p>{this.state.emailError}</p>}
            {error ? <p>{error.message}</p> : <p></p>}
            {this.state.error && <p>{this.state.emailError}</p>}
          </FormGroup>

          <FormGroup bsSize="large">
            <FormControl
              style={{ backgroundColor: "#EEE" }}
              value={password}
              onChange={this.onPasswordChange}
              type="password"
              placeholder="Password"
              secure
            />
            {this.state.passwordError && <p>{this.state.passwordError}</p>}
          </FormGroup>

          <Button disabled={isInvalid} type="submit">
            Create account
          </Button>
          {this.state.error && <p>{this.state.error}</p>}
        </form>
      </FormGroup>
    );
  }
}

const SignUpLink = () => (
  <p style={{ fontSize: 16 }}>
    Don't have an account?{" "}
    <Link to={routes.SIGN_UP} underline>
      Request access
    </Link>
  </p>
);

export default withRouter(CreateUserAccount);

export { SignUpForm, SignUpLink };
