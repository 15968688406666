import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withAuthorization from "../session/withAuthorization";
import FamilyCard from "./FamilyCard";
import ListIndexFilters from "./ListIndexFilters";
import itemSelector from "./../../selectors/selector";
import Navigation from "../layout/Navigation.js";
import logo from "../../assets/images/logo.svg";

const Dashboard = (props) => {
  const familiesArr = props.families.sort((a, b) =>
    a.family_name.localeCompare(b.family_name)
  );

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Navigation />
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          padding: 16,
        }}
      >
        <div style={{ maxWidth: 800 }}>
          <h1 style={{ fontWeight: "bold" }}>Family directory</h1>

          <div>
            <ListIndexFilters />
          </div>

          {props.families.length === 0 ? (
            <h2>
              loading...
              <span role="img" aria-label="sad emoji">
                🐣
              </span>
            </h2>
          ) : (
            familiesArr.map((famility) => (
              <FamilyCard key={famility.id} {...famility} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  user: state.user,
  families: itemSelector(state.families, state.filtersText),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUser: (user) => dispatch({ type: "USER_SET", user }),
});

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
