// LESSONS Reducer
import { ADD_LESSON, FETCH_TIMELINE_LESSONS, FETCH_PROFILE_LESSONS, FETCH_MORE_LESSONS, ADD_ITEM, REMOVE_LESSON, SET_LESSONS } from '../actions/lessons'


const lessonsReducerDefaultState = [];

export default (state = lessonsReducerDefaultState, action) => {
  switch (action.type) {
    case ADD_LESSON:
    return [
      ...state,
      action.lesson
    ];

      case ADD_ITEM:
      return [
        ...state,
        action.itemBody
      ];
      
    case REMOVE_LESSON:
      return state.filter(({ id }) => id !== action.id);
    
    // case EDIT_LIST:
    //   return state.map((list) => {
    //     if (list.id === action.id) {
    //       return {
    //         ...list,
    //         ...action.updates
    //       };
    //     } else {
    //       return list;
    //     };
    //   });
    
    case SET_LESSONS:
      return action.lessons;
      
    case FETCH_PROFILE_LESSONS:
      return action.payload;
    
    case FETCH_MORE_LESSONS:
      return action.lessons;
      
    case FETCH_TIMELINE_LESSONS:
      return action.lessons;
      
    default:
      return state;
  }
};
