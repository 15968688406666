import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import FamilyForm from "./FamilyForm";
import { startAddFamily } from "../../actions/families";
import Header from "../layout/Header";
import withAuthorization from "../session/withAuthorization";

export class AddFamilyPage extends React.Component {
  onSubmit = (familiy) => {
    this.props.startAddFamily(familiy);
    this.props.history.push("/");
  };
  render() {
    return (
      <div>
        <Header />
        <div className="container" style={{ marginTop: 120 }}>
          <div className="content-container">
            <FamilyForm onSubmit={this.onSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddFamily: (familiy) => dispatch(startAddFamily(familiy)),
});

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  user: state.user,
});

// export default connect(undefined, mapDispatchToProps)(AddFamilyPage);

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(AddFamilyPage);
