import React, { Component } from "react";
import "../../styles/App.css";
import _ from "lodash";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";
import { Accordion, Card } from "react-bootstrap";

class FamilyCard extends Component {
  render() {
    const {
      address,
      bio,
      children,
      city,
      email,
      family_name,
      father_phone_number,
      fathers_name,
      mother_phone_number,
      mothers_name,
      photoURL,
      state,
      zipcode,
    } = this.props;

    const childrenArr = _.toArray(children);

    const colorOptions = [
      "#FFB3BA", // Pale Pink
      "#FFDFBA", // Peach
      "#FFFFBA", // Pale Yellow
      "#Baffc9", // Mint
      "#bacaff", // Sky Blue
      "#D8BAFF", // Lavender
      "#FFC6FF", // Pale Purple
      "#bafff8", // Light Turquoise
      "#FFD8D8", // Pale Coral
      "#C2F0C2", // Pale Green
    ];

    const getRandomIndex = (colorOptions) => {
      if (colorOptions.length === 0) {
        return null;
      }

      const randomIndex = Math.floor(Math.random() * colorOptions.length);
      return randomIndex;
    };

    const randomIndex = getRandomIndex(colorOptions);

    return (
      <div>
        <div className="list-card-style">
          <div
            style={{
              borderTopLeftRadius: 32,
              borderTopRightRadius: 32,
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div>
              <img
                src={photoURL !== "" ? photoURL : defaultPhotoURL}
                // className="list-profile-avatar"
                style={{ width: "100%" }}
                alt="profile img"
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 16,
              }}
            >
              <div
                style={{ fontSize: 22, fontWeight: "bold", marginBottom: 8 }}
              >
                {family_name} Family
                <p>
                  <i
                    className="fas fa-map-pin nav-bar-icon"
                    style={{ fontSize: 14, marginRight: 4, color: "#020202" }}
                  />
                  {city}
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: 24,
              paddingBottom: 16,
            }}
          >
            <strong>Children</strong>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {childrenArr.map((student, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: 18,
                    marginTop: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 50,
                      width: 50,
                      backgroundColor: colorOptions.at(index),
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontSize: 32 }}>
                        {student.child_name.charAt(0)}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <p>{student.child_name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              paddingLeft: 24,
              display: "flex",
              flex: 1,
              flexDirection: "row",
              // justifyContent: "space-around",
              paddingBottom: 16,
              marginTop: 16,
            }}
          >
            <div style={{ flex: 1 }}>
              <strong>Father's info</strong>
              <p>{fathers_name}</p>
              <p>
                <i
                  className="fas fa-mobile nav-bar-icon"
                  style={{ fontSize: 12 }}
                ></i>{" "}
                {father_phone_number}
              </p>
            </div>
            <div style={{ flex: 1 }}>
              <strong>Mother's info</strong>
              <p>{mothers_name}</p>
              <p>
                <i
                  className="fas fa-mobile nav-bar-icon"
                  style={{ fontSize: 12 }}
                ></i>{" "}
                {mother_phone_number}
              </p>
            </div>
          </div>

          <div
            style={{
              paddingLeft: 24,
              display: "flex",
              flexDirection: "column",
              paddingBottom: 16,
              marginTop: 16,
            }}
          >
            <strong>Address</strong>
            <p>
              {address}, {city} {zipcode} {state}
            </p>
          </div>

          <div
            style={{
              paddingLeft: 24,
              display: "flex",
              flexDirection: "column",
              paddingBottom: 16,
              marginTop: 16,
            }}
          >
            <strong>Email</strong>
            <p>{email}</p>
          </div>

          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              display: "flex",
              flexDirection: "column",
              paddingBottom: 16,
              marginTop: 16,
              paddingBottom: 64,
            }}
          >
            <strong>About us</strong>
            <p>{bio}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyCard;
