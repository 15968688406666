export const LANDING = "/";
export const DASHBOARD = "/dashboard";
export const EXPLORE = "/explore/user";
export const ACTIVITY = "/activity/users";
export const SIGN_UP = "/signup/user";
export const SIGN_IN = "/signin/user";
export const PASSWORD_FORGET = "/forgot-password/user";
export const ACCOUNT = "/account/user";
export const PROFILE = "/:uid";
export const PROFILE_FAMILY_DETAIL = "/:uid/lessons/:id";
export const FAMILY_NEW = "/family/new";
export const PROFILE_FEATURED = "/:uid/featured";
export const FAMILY_DETAIL = "/:uid/family-view/:id";
export const FAMILY_EDIT = "/edit/:id";
export const FOLLOWING_FRIENDS = "/:uid/following";
export const FOLLOWERS_FRIENDS = "/:uid/followers";
export const TERMS_PRIVACY = "/:uid/legal/privacy/";
export const ABOUT = "/terms/legal/privacy/";
export const APP = "/terms/legal/privacy/";
