// LESSONS Reducer
import {
  ADD_FAMILY,
  REMOVE_FAMILY,
  SET_FAMILIES,
  FETCH_TIMELINE_FAMILIES,
} from "../actions/families";

const familiesReducerDefaultState = [];

export default (state = familiesReducerDefaultState, action) => {
  switch (action.type) {
    case ADD_FAMILY:
      return [...state, action.family];

    case REMOVE_FAMILY:
      return state.filter(({ id }) => id !== action.id);

    // case EDIT_LIST:
    //   return state.map((list) => {
    //     if (list.id === action.id) {
    //       return {
    //         ...list,
    //         ...action.updates
    //       };
    //     } else {
    //       return list;
    //     };
    //   });

    case SET_FAMILIES:
      return action.families;

    case FETCH_TIMELINE_FAMILIES:
      return action.families;

    default:
      return state;
  }
};
