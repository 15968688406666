import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { PasswordForgetLink } from "../passwordForget";
import { auth } from "../../firebase";
import * as routes from "../../constants/routes";
import logo from "../../assets/images/teachstream_logo@2x.png";

const SignInPage = ({ history }) => (
  <div
    style={{
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 100,
    }}
  >
    <div>
      <div style={{ display: "flex" }}>
        <img src={logo} className="app-logo" alt="logo" draggable="false" />
      </div>

      <div
        style={{
          minWidth: 400,
          backgroundColor: "#E9E9E9",
          padding: 16,
          borderRadius: 12,
        }}
      >
        <p style={{ fontSize: 16 }}>Log in to you account</p>
        <SignInForm history={history} />
      </div>
      <div>
        <PasswordForgetLink />
      </div>
    </div>
  </div>
);

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.DASHBOARD);
      })
      .catch((error) => {
        this.setState(updateByPropertyName("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <div bsSize="large">
        <form onSubmit={this.onSubmit}>
          <div bsSize="large" style={{ marginTop: 12, marginBottom: 24 }}>
            <input
              style={{
                height: 55,
                width: "100%",
                borderRadius: 12,
                paddingLeft: 8,
                backgroundColor: "#fff",
              }}
              value={email}
              onChange={(event) =>
                this.setState(updateByPropertyName("email", event.target.value))
              }
              type="text"
              placeholder="Email address"
            />
          </div>
          <div bsSize="large" style={{ marginBottom: 24 }}>
            <input
              style={{
                height: 55,
                width: "100%",
                borderRadius: 12,
                paddingLeft: 8,
                backgroundColor: "#fff",
              }}
              value={password}
              onChange={(event) =>
                this.setState(
                  updateByPropertyName("password", event.target.value)
                )
              }
              type="password"
              placeholder="Password"
            />
          </div>
          <button
            bsStyle="primary"
            bsSize="large"
            className="btn-blank btn-blue"
            disabled={isInvalid}
            type="submit"
          >
            Sign In
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

export default withRouter(SignInPage);

export { SignInForm };
