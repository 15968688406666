import React from "react";
import { connect } from "react-redux";
import header_img from "../../assets/images/header_img@2x.png";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import logo from "../../assets/images/teachstream_logo@2x.png";
const HeroHeader = (props) => {
  return (
    <div>
      <div className="box_header_overly" />

      <div className="container">
        <div style={{ marginTop: 200 }}>
          <div className="col-md-6">
            <div style={{ marginBottom: 16 }}>
              <img
                src={logo}
                className="app-logo"
                alt="logo"
                draggable="false"
              />
              <p style={{ color: "#212121", fontSize: 22 }}>
                A platform dedicated to streamlining education.
              </p>
            </div>

            {props.authUser ? (
              <Link to={routes.DASHBOARD}>
                <button
                  className="btn btn-disable btn-lg"
                  style={{
                    backgroundColor: "#FF1744",
                    color: "#FFFFFF",
                    fontWeight: 900,
                  }}
                >
                  Directory
                </button>
              </Link>
            ) : (
              <Link to={routes.SIGN_IN}>
                <button
                  className="btn btn-disable btn-lg"
                  style={{
                    backgroundColor: "#FF1744",
                    color: "#FFFFFF",
                    fontWeight: 900,
                  }}
                >
                  Sign in
                </button>
              </Link>
            )}
          </div>
          <div className="col-md-6">
            <img
              className="img-responsive"
              src={header_img}
              draggable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps,
});

export default connect(mapStateToProps, undefined)(HeroHeader);
