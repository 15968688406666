import database from "../firebase/firebase";
import firebase from "firebase";
import _ from "lodash";

// const profile = 'https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/profile_images%2FQcroUjlQ16PvL2rUzmFKvBKCfuH3%2Fjessie_small.jpg?alt=media&token=58f28991-79da-493a-8c61-bd3af5baa238'
/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_FAMILIES = "SET_FAMILIES";
export const FETCH_TIMELINE_FAMILIES = "FETCH_TIMELINE_FAMILIES";
export const ADD_FAMILY = "ADD_FAMILY";
export const REMOVE_FAMILY = "REMOVE_FAMILY";
export const EDIT_FAMILY = "EDIT_FAMILY";

/**
 |--------------------------------------------------
 | Fetch families for signed in user
 |--------------------------------------------------
 */

// SET LISTS
export const setFamilies = (families) => ({
  type: SET_FAMILIES,
  families,
});

export const fetchFamilies = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    // return database.ref(`/timeline/${uid}`).once("value").then(snapshot => {
    return database
      .ref(`/families`)
      .once("value")
      .then((snapshot) => {
        const families = [];

        snapshot.forEach((childSnapshot) => {
          families.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        // console.log(families, "TEST LESSONS")
        dispatch(setFamilies(_.reverse(families)));
      });
  };
};

/**
 |--------------------------------------------------
 | Fetch families for signed in user
 |--------------------------------------------------
 */

// ADD_FAMILY
export const addFamiliy = (family) => ({
  type: ADD_FAMILY,
  family,
});

export const startAddFamily = (familyData = {}) => {
  return (dispatch) => {
    const {
      address = "",
      bio = "",
      children = "",
      city = "",
      id = "",
      displayName = "",
      email = "",
      family_name = "",
      father_phone_number = "",
      fathers_name = "",
      group_name = "",
      hidden = false,
      mother_phone_number = "",
      mothers_name = "",
      photoURL = "",
      state = "",
      zipcode = "",
      createdAt = 0,
    } = familyData;

    const family = {
      address,
      bio,
      children,
      city,
      id,
      displayName,
      email,
      family_name,
      father_phone_number,
      fathers_name,
      group_name,
      hidden,
      mother_phone_number,
      mothers_name,
      photoURL,
      state,
      zipcode,
      createdAt,
    };

    let updates = {};

    updates[`/families/${id}`] = family;
    // updates[`/users/${currentUser.displayName}/families/${id}`] = family;
    // updates[`/timeline/${currentUser.uid}/${id}`] = family;

    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addFamiliy({
            id: ref,
            ...family,
          })
        );
      });
  };
};

// REMOVE_FAMILY
export const removeFamily = ({ id } = {}) => ({
  type: REMOVE_FAMILY,
  id,
});

export const startRemoveFamily = ({ id } = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    return database
      .ref(`users/${currentUser.displayName}/families/${id}`)
      .remove()
      .then(() => {
        dispatch(removeFamily({ id }));
      })
      .then(() => {
        const followersRef = database.ref(`followers/${currentUser.uid}`);
        followersRef.on("value", (snapshot) => {
          const followers = Object.keys(snapshot.val());
          followers.forEach((key) =>
            database
              .ref(`/timeline/${key}/${id}`)
              .remove()
              .then(() => {
                dispatch(removeFamily({ id }));
              })
          );
        });
      });
  };
};

// EDIT_FAMILY
export const editFamily = (id, updates) => ({
  type: EDIT_FAMILY,
  id,
  updates,
});

export const startEditFamily = (id, updates) => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    // return database.ref(`followers/${user.uid}`).once("value", snapshot => {
    // Read followers keys and turn into hash
    // const followers = Object.keys(snapshot.val())
    // Create object to send updated to
    let updates_ = {};
    // Loop through followers and send updates
    // followers.forEach(key => updates_[`/timeline/${key}/${id}`] = updates)
    // Sendd updates to Users own Lists
    // updates_[`users/${user.displayName}/families/${id}`] = updates
    // Send updates to Lists node
    updates_[`/families/${id}`] = updates;

    // updates_[`/timeline/${user.uid}/${id}`] = updates
    return database
      .ref()
      .update(updates_)
      .then(() => {
        dispatch(editFamily(id, updates));
      });

    // })
  };
};
