import React, { useEffect, useRef, useState, Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo from '../../assets/images/icon@2x.png'
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import { Carousel } from 'react-responsive-carousel';
import _ from "lodash";
import Webcam from "react-webcam";
import { db } from '../../firebase/firebase'


class LessonRoom extends Component {
  constructor(props){
    super(props)
    this.state = {
      lessonObj: {},
      currentSlide: 0,
    }
  }

  componentDidMount(){

    const { id } = this.props.match.params;

    const { displayName } = this.props.user
    
    const roomID = this.props.location.pathname;
    
    db.ref(`users/${displayName}/lessons`).child(`${id}`).once('value').then(snap =>{
      console.log(snap.val(), "SNAP")
      this.setState({
        lessonObj: snap.val()
      });
    })

    let slideRef = db.ref(`lessons/${roomID}`)
      slideRef.on("value", snapshot => {
        var currentSlide = snapshot.child(`/current_slide`).val(); // "Ada"
        this.setState({ currentSlide })
    });

  }

  render(){

    const { id } = this.props.match.params;
    
    const lessonsArr = _.toArray(this.props.lessons)
    const lesson = lessonsArr.find(lesson => lesson.id === id);

    const { slides } = _.toPlainObject(lesson)
    
    const images = _.toArray(slides)
    const imgs = Object.values(images)

    const {uid} = _.toPlainObject(this.props.user);
    
    
    

    return(
      <div style={{backgroundColor: '#FFF'}}>

        <nav className="navbar navbar-default" style={{backgroundColor: '#FFF'}}>
            <div className="container-fluid">
              
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a href="/dashboard"><img src={logo} className="app-logo-header" alt="teach stream" /></a>
              </div>

              <div id="navbar" className="navbar-collapse collapse">
                
                <ul className="nav navbar-nav navbar-right">
                      <li>
                        {  
                          uid == lesson.uid ? (
                                <Link to={`/edit/${id}`}>
                                  Edit
                                </Link>
                          ) : (
                            <div />
                          )
                        }
                      </li>
                  <li className="dropdown">

                  <div className="dropdown profile-avatar-container">
                    <div className="dropdown-toggle nav-profile-img-wrapper" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <img src={this.props.user.photoURL} className="nav-profile-img" alt="user avatar" />
                    </div>
                
                    <ul className="dropdown-menu">
                      <li className="dropdown-header">Settings</li>
                      <li><Link to={'/account/user'}>Account</Link></li>
                    </ul>
                  </div>
                    
                  </li>
                </ul>

              </div>

            </div>
          </nav>





        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 60}}>

        <div style={{width: '60%', maxWidth: 920, paddingRight: 100}}>
        
          
          <Carousel
            // style={{maxHeight: 980}}
            useKeyboardArrows
            infiniteLoop
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            >
            
            {_.toArray(imgs).map((i) => (
              <div>
                <img src={i.img} />
              </div>
            ))}

          </Carousel>

        </div>

        <div style={{width: '40%', maxWidth: 440}}>
        <div className="cam_container">
          <div style={{borderRadius: 70, overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{justifyContent: 'center'}}>
              <Webcam />
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  lessons: state.lessons
});

export default connect(mapStateToProps, undefined)(LessonRoom);


