import React, { Component } from "react";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import { auth } from "../../firebase";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;

    auth
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch((error) => {
        this.setState(updateByPropertyName("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <FormGroup>
        <form onSubmit={this.onSubmit}>
          <FormGroup>
            <FormControl
              value={passwordOne}
              onChange={(event) =>
                this.setState(
                  updateByPropertyName("passwordOne", event.target.value)
                )
              }
              type="password"
              placeholder="New Password"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              value={passwordTwo}
              onChange={(event) =>
                this.setState(
                  updateByPropertyName("passwordTwo", event.target.value)
                )
              }
              type="password"
              placeholder="Confirm new password"
            />
          </FormGroup>

          <Button
            style={styles.submitUpdatesButton}
            disabled={isInvalid}
            type="submit"
          >
            <p>Reset my password</p>
          </Button>

          {error && <p>{error.message}</p>}
        </form>
      </FormGroup>
    );
  }
}

export default PasswordChangeForm;

const styles = {
  container: {
    marginTop: "20px",
  },
  privateInfoContainer: {
    paddingTop: 20,
    // borderColor: '#CCCCCC',
    // borderTopWidth: 2,
  },
  privateInfoText: {
    color: "#9E9E9E",
    fontWeight: "bold",
  },
  userAccountTextInput: {
    flexDirection: "row",
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: "#FFFFFF",
    height: 40,
    flex: 1,
    borderColor: "#CCCCCC",
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    width: 90,
    paddingTop: 20,
    fontWeight: "bold",
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: "#304FFE",
    padding: 10,
    backgroundColor: "#304FFE",
    height: 40,
  },
  submitButtonText: {
    color: "#ffffff",
    textAlign: "center",
  },
};
