import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, FormControl } from "react-bootstrap";
import { setTextFilter } from "../../actions/filtersText";

class FamiliesFilters extends Component {
  render() {
    return (
      <div style={{ display: "flex" }}>
        <FormGroup bsSize="large" style={{ display: "flex", width: "100%" }}>
          <FormControl
            type="text"
            placeholder={"Fliter family by name"}
            style={{
              backgroundColor: "#f5f5f5",
              height: 55,
              borderRadius: 12,
            }}
            value={this.props.filtersText.text}
            onChange={(e) => {
              this.props.dispatch(setTextFilter(e.target.value));
            }}
          />
        </FormGroup>

        <div style={{ display: "flex" }}>
          {this.props.filtersText.text !== "" ? (
            <button
              style={{
                marginLeft: 18,
                backgroundColor: "#f5f5f5",
                height: 55,
                borderRadius: 12,
              }}
              onClick={() => {
                this.props.dispatch(setTextFilter(""));
              }}
            >
              <p>Clear</p>
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filtersText: state.filtersText,
  };
};

export default connect(mapStateToProps)(FamiliesFilters);
