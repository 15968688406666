import React, { Component } from "react";
import { connect } from "react-redux";
import { startEditLesson, startRemoveList } from "../../actions/lessons";
import LessonEditForm from "./FamilyEditForm";
// import ProfileSidebar from "../publicProfile/ProfileSidebar";
import Layout from "../layout/Layout";

class EditLessonView extends Component {
  // removeListButton() {
  //   return (

  //       <button style={{ marginBottom: "10px" }}
  //               onClick={() => {
  //               this.props.startRemoveList({ id: this.props.match.params.id });
  //               this.props.history.push("/");
  //             }}>
  //         <div className="remove-tierly-div-style">
  //           <i className="fa fa-trash" aria-hidden="true" /> Remove
  //         </div>
  //       </button>

  //   );
  // }

  render() {
    console.log(this.props, "TISSUE EDITS");
    return (
      <Layout>
        <div>
          <div>
            <div md={6} sm={12} xs={12}>
              <LessonEditForm
                lesson={this.props.lesson}
                onSubmit={(lesson) => {
                  this.props.startEditLesson(this.props.lesson.id, lesson);
                  this.props.history.push("/");
                }}
              />
            </div>

            {/* <div md={3} sm={12} xs={12}>
                {this.removeListButton()}
              </div> */}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lesson: state.lessons.find((lesson) => lesson.id === props.match.params.id),
  userProps: state.userProps,
});

const mapDispatchToProps = (dispatch) => ({
  startRemoveList: (data) => dispatch(startRemoveList(data)),
  startEditLesson: (id, lesson) => dispatch(startEditLesson(id, lesson)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLessonView);
