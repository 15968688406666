import React, { Component } from "react";
import HeroHeader from "./HeroHeader";
import "../../styles/App.css";

const LandingPage = () => {
  return (
    <div>
      <div>
        <HeroHeader />
      </div>
    </div>
  );
};
export default LandingPage;
