import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import firebase from "firebase";
import database from "../../firebase/firebase";
import FileUploader from "react-firebase-file-uploader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Button, Col } from "react-bootstrap";
import _ from "lodash";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  width: "100%",
  // change background colour if dragging
  background: isDragging ? "#EEEEEE" : "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#FFFFFF" : "f5f5f7",
  width: "100%",
});

class FamiliyForm extends React.Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      id: firebase.database().ref().push().key,
      file: [null],
      files: [],
      items: [],
      filenames: [null],
      downloadURLs: [null],
      isUploading: false,
      uploadProgress: 0,
      createdAt: props.familiy ? moment(props.familiy.createdAt) : moment(),
      address: "",
      bio: "",
      children: [],
      city: "",
      displayName: "",
      email: "",
      family_name: "",
      father_phone_number: "",
      fathers_name: "",
      group_name: "",
      hidden: false,
      mother_phone_number: "",
      mothers_name: "",
      photoURL: "",
      state: "",
      zipcode: "",
      error: "",
    };
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  handleAddItem(child_name) {
    if (!child_name) {
      return "Enter valid value to add item";
    } else if (this.state.items.indexOf(child_name) > -1) {
      return "This item already exists";
    }

    let item = {
      child_name,
      id: _.toString(this.state.items.length + 1),
    };

    this.setState((prevState) => {
      return {
        items: prevState.items.concat(item),
      };
    });
  }

  removeItem(item) {
    const updatedItemCount = this.state.items.filter((items) => {
      return items !== item;
    });
    this.setState({
      items: [...updatedItemCount],
    });
  }

  onClassNameChange = (e) => {
    const class_name = e.target.value;
    this.setState(() => ({ class_name }));
  };

  onFamilysNameChange = (e) => {
    const family_name = e.target.value;
    this.setState(() => ({ family_name }));
  };

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }));
  };

  onFathersNameChange = (e) => {
    const fathers_name = e.target.value;
    this.setState(() => ({ fathers_name }));
  };

  onFathersPhoneChange = (e) => {
    const father_phone_number = e.target.value;
    this.setState(() => ({ father_phone_number }));
  };

  onMothersNameChange = (e) => {
    const mothers_name = e.target.value;
    this.setState(() => ({ mothers_name }));
  };

  onMothersPhoneNumberChange = (e) => {
    const mother_phone_number = e.target.value;
    this.setState(() => ({ mother_phone_number }));
  };

  onAddressChange = (e) => {
    const address = e.target.value;
    this.setState(() => ({ address }));
  };

  onCityChange = (e) => {
    const city = e.target.value;
    this.setState(() => ({ city }));
  };

  onZipcodeChange = (e) => {
    const zipcode = e.target.value;
    this.setState(() => ({ zipcode }));
  };

  onStateChange = (e) => {
    const state = e.target.value;
    this.setState(() => ({ state }));
  };

  onChildrenChange = (e) => {
    const children = e.target.value;
    this.setState(() => ({ children }));
  };

  onBioChange = (e) => {
    const bio = e.target.value;
    this.setState(() => ({ bio }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.family_name) {
      this.setState(() => ({ error: "Please provide familiy name." }));
    } else {
      this.setState(() => ({ error: "" }));
      this.props.onSubmit({
        address: this.state.address,
        bio: this.state.bio,
        children: this.state.items,
        city: this.state.city,
        displayName: this.state.family_name,
        email: this.state.email,
        family_name: this.state.family_name,
        items: this.state.items,
        father_phone_number: this.state.father_phone_number,
        fathers_name: this.state.fathers_name,
        group_name: this.state.group_name,
        hidden: false,
        mother_phone_number: this.state.mother_phone_number,
        mothers_name: this.state.mothers_name,
        photoURL: this.state.photoURL,
        state: this.state.state,
        zipcode: this.state.zipcode,
        id: this.state.id,
        createdAt: this.state.createdAt.valueOf(),
      });
    }
  };

  uploadMultipleFiles(e) {
    this.fileObj.push(e.target.files);
    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }
    this.setState({ file: this.fileArray });
  }

  // UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0,
    });

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress,
    });

  handleUploadError = (error) => {
    this.setState({
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref(`families/${this.state.id}/`)
      .child(filename)
      .getDownloadURL();

    this.setState({ photoURL: downloadURL });

    this.setState((oldState) => ({
      uploadProgress: 100,
      isUploading: false,
    }));
  };

  // UPLOAD CODE

  render() {
    return (
      <div className="container-fluid">
        <form onSubmit={this.onSubmit}>
          {this.state.error && (
            <p className="form__error">{this.state.error}</p>
          )}

          <div className="col-md-9">
            <div className="row">
              <div className="form-group">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h4>Add family</h4>
                  <br />

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Family name"
                      value={this.state.family_name}
                      onChange={this.onFamilysNameChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.onEmailChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Father's name"
                      value={this.state.fathers_name}
                      onChange={this.onFathersNameChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Father phone number"
                      value={this.state.fathers_phone_number}
                      onChange={this.onFathersPhoneChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Mother's name"
                      value={this.state.mothers_name}
                      onChange={this.onMothersNameChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Mother's phone number"
                      value={this.state.mother_phone_number}
                      onChange={this.onMothersPhoneNumberChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Address"
                      value={this.state.address}
                      onChange={this.onAddressChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="City"
                      value={this.state.city}
                      onChange={this.onCityChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Zipcode"
                      value={this.state.zipcode}
                      onChange={this.onZipcodeChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="State"
                      value={this.state.state}
                      onChange={this.onStateChange}
                    />
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <input
                      style={{
                        height: 55,
                        width: "100%",
                        borderRadius: 12,
                        paddingLeft: 8,
                        backgroundColor: "#eee",
                      }}
                      type="text"
                      placeholder="Bio"
                      value={this.state.bio}
                      onChange={this.onBioChange}
                    />
                  </div>

                  <div>
                    {this.state.items.length === 0 ? (
                      <></>
                    ) : (
                      <div style={{ height: "auto" }}>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {this.state.items.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        className="item-card"
                                      >
                                        <div className="item-detail-wrapper">
                                          <div>
                                            <p
                                              className="item-content"
                                              style={{ fontSize: 18 }}
                                            >
                                              {item.child_name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="item-link-container">
                                          <div
                                            className="delete-link-icon"
                                            style={{ marginRight: 12 }}
                                          >
                                            <div
                                              index={index}
                                              onClick={(e) =>
                                                this.removeItem(item)
                                              }
                                            >
                                              <i className="fas fa-trash-alt delete-icon"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    )}
                  </div>

                  <div style={{ width: "100%", marginBottom: 12 }}>
                    <AddItemForm
                      items={this.props.items}
                      onSubmit={this.handleAddItem}
                    />
                  </div>

                  <div style={{ marginBottom: 16 }}>
                    <FileUploader
                      accept="image/*"
                      name="image-uploader-multiple"
                      storageRef={firebase
                        .storage()
                        .ref(`families/${this.state.id}/`)}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                      multiple
                    />
                    <p>Progress: {this.state.uploadProgress}</p>
                  </div>
                  <div sty>
                    <button
                      type="button"
                      onClick={this.onSubmit}
                      className="btn btn-primary btn-lg"
                    >
                      Post family
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3"></div>
        </form>
      </div>
    );
  }
}

class AddItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      items: this.props.items ? this.props.items : [],
      child_name: props.item ? props.item.child_name : "",
      error: undefined,
    };
  }

  state = {
    child_name: "",
    error: "",
  };

  onContentChange = (e) => {
    const child_name = e.target.value;
    this.setState(() => ({ child_name }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.child_name) {
      this.setState(() => ({ error: "Please provide item" }));
    } else {
      this.setState(() => ({ error: "", child_name: "" }));
      this.props.onSubmit(this.state.child_name);
    }
  };

  onKeyPress(e) {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <div style={{ display: "flex", flex: 1 }}>
        {this.state.error && <p>{this.state.error}</p>}
        <Form
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
          autoComplete="off"
          onKeyPress={this.onKeyPress}
          onSubmit={this.onSubmit}
        >
          <div
            style={{
              borderRadius: 12,
              display: "flex",
              flex: 1,
              flexDirection: "row",
              backgroundColor: "#eee",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 16, marginRight: 6 }}>
              <i className="fas fa-child" />
            </div>

            <input
              type="text"
              placeholder="Child (name, age)"
              value={this.state.child_name}
              onChange={this.onContentChange}
              style={{
                height: 55,
                width: "100%",
                borderRadius: 12,
                paddingLeft: 8,
                backgroundColor: "#eee",
              }}
              autoComplete="off"
            />
          </div>

          <div
            onClick={this.onSubmit}
            className="add-item-btn-container"
            style={{ height: "auto", paddingLeft: 18 }}
          >
            <div
              className="add-item-btn"
              style={{ padding: 12, backgroundColor: "#eee", height: 55 }}
            >
              <strong>+ Add child</strong>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, undefined)(FamiliyForm);
