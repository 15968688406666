import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import * as routes from "../../constants/routes";
import ScrollToTop from "../utilities/ScrollToTop";
import SignUpPage from "../signup";
import SignInPage from "../signIn";
import PasswordForgetPage from "../passwordForget";
import Timeline from "../timeline";
import AccountPage from "../account";
import withAuthentication from "../session/withAuthentication";
import Landing from "../landing";
import EditFamilyView from "../families/EditFamilyView";
import FamilyRoom from "../families/FamilyRoom";
import AddFamilyView from "../families/AddFamilyView";
import TermsAndPrivacy from "../utilities/TermsAndPrivacy";
import About from "../utilities/About";
import MobileApp from "../utilities/MobileApp";

export const history = createHistory();
const App = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <div>
          <Route exact path={routes.LANDING} component={Landing} />
          <Route exact path={routes.SIGN_IN} component={SignInPage} />
          <Route exact path={routes.DASHBOARD} component={Timeline} />
          <Route exact path={routes.FAMILY_NEW} component={AddFamilyView} />
          <Route exact path={routes.FAMILY_DETAIL} component={FamilyRoom} />
          <Route exact path={routes.FAMILY_EDIT} component={EditFamilyView} />
          <Route exact path={routes.ACCOUNT} component={AccountPage} />
          <Route
            exact
            path={routes.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route
            exact
            path={routes.TERMS_PRIVACY}
            component={TermsAndPrivacy}
          />
          <Route exact path={routes.ABOUT} component={About} />
          <Route exact path={routes.APP} component={MobileApp} />
        </div>
      </Switch>
    </ScrollToTop>
  </Router>
);

export default withAuthentication(App);
