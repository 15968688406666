import React, { Component } from "react";
import { connect } from "react-redux";
import { firebase } from "../../firebase/firebase";
// import { div } from "react-bootstrap";
import withAuthorization from "../session/withAuthorization";
import PasswordChangeForm from "../passwordChange";
import Layout from "../layout/Layout";
import AccountForm from "./AccountForm";
import SortableLinks from "./SortableLinks";
import { compose } from "recompose";
import { SignUpForm } from "../signup/CreateUserAccount";

class AccountPage extends Component {
  render() {
    const { displayName, admin } = this.props.userProps;
    return (
      <Layout>
        <div className="container">
          <div>
            <div className="col-md-3" />
            <div className="col-md-6">
              <div md={12} sm={12}>
                <p style={styles.leftTitleContainer}>
                  Edit Profile: @{displayName}
                </p>
                <br />
                {/* <div className="list-card-style">
                  <div className="list-card">
                    <div style={{ padding: 16 }}>
                      <ImageSelectorComponent />
                    </div>
                  </div>
                </div> */}

                <div className="list-card-style">
                  <div style={{ padding: 16 }}>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <PasswordChangeForm />
                    </div>
                  </div>
                </div>

                {admin ? (
                  <div className="list-card-style">
                    <div style={{ padding: 16 }}>
                      <p style={styles.leftTitleContainer}>
                        Create account for user
                      </p>
                      <br />
                      <SignUpForm />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </Layout>
    );
  }
}

class ImageSelectorComponent extends Component {
  constructor(state) {
    super(state);
    this.state = {
      picture: null,
      pictureURL: null,
    };
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ pictureURL: user.photoURL });
    });
  }
  render() {
    const { input } = this.props;
    return (
      <div>
        <form>
          <div md={2} sm={6} className="no-margin">
            <PreviewPicture pictureURL={this.state.pictureURL} />
          </div>
          <div md={10} sm={6} className="no-margin">
            <p>Change Profile Photo</p>
            <input
              type="file"
              onChange={(event) => {
                this.displayPicture(event);
              }}
              {...input}
            />
          </div>
        </form>
      </div>
    );
  }

  displayPicture(event) {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        picture: file,
        pictureURL: reader.result,
      });
    };

    const { uid, displayName } = firebase.auth().currentUser;
    const folderName = `${uid}`;
    const imageName = `${uid}.jpg`;

    reader.readAsDataURL(file);

    var metadata = {
      contentType: "image/jpeg",
    };

    const imageRef = firebase
      .storage()
      .ref(`profile_images/${folderName}/${imageName}`);

    imageRef
      .put(file, metadata)
      .then(() => {
        return imageRef.getDownloadURL();
      })
      .then((url) => {
        firebase.auth().onAuthStateChanged((user) => {
          user.updateProfile({ photoURL: url });
        });
        firebase
          .database()
          .ref(`/users/${displayName}`)
          .child("photoURL")
          .set(url);
      })
      .then(() => alert("Image uploaded"))
      .catch((error) => {});
  }
}

const PreviewPicture = (props) => {
  const { pictureURL } = props;
  return (
    <div className="account-img-wrapper">
      <img
        src={pictureURL}
        className="account-user-img"
        alt="user avatar"
        draggable={false}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProps: state.userProps,
  authUser: state.sessionState.authUser,
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, undefined)
)(AccountPage);

const styles = {
  privateInfoContainer: {
    paddingTop: 20,
    // borderColor: '#CCCCCC',
    // borderTopWidth: 2,
  },
  privateInfoText: {
    color: "#9E9E9E",
    fontWeight: "bold",
  },
  userAccountTextInput: {
    flexDirection: "row",
  },
  searchFieldContainer: {
    padding: 10,
    margin: 10,
    backgroundColor: "#FFFFFF",
    height: 40,
    flex: 1,
    borderColor: "#CCCCCC",
    borderBottomWidth: 1,
  },
  leftTitleContainer: {
    paddingTop: 20,
    fontWeight: "bold",
  },
  submitUpdatesButton: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: "#304FFE",
    padding: 10,
    margin: 10,
    backgroundColor: "#304FFE",
    height: 40,
  },
  submitButtonText: {
    color: "#ffffff",
    textAlign: "center",
  },
};
