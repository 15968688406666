import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const prodConfig = {
  apiKey: "AIzaSyBpe5wRUtk4fjcJhWng7mqoRF9R7cw5RMo",
  authDomain: "video-app-fdc27.firebaseapp.com",
  databaseURL: "https://video-app-fdc27.firebaseio.com",
  projectId: "video-app-fdc27",
  storageBucket: "video-app-fdc27.appspot.com",
  messagingSenderId: "886912511306",
  appId: "1:886912511306:web:5bbc36080812e626dda5b3",
  measurementId: "G-DYT0XMKDCC"
};

const devConfig = {
  apiKey: "AIzaSyBpe5wRUtk4fjcJhWng7mqoRF9R7cw5RMo",
  authDomain: "video-app-fdc27.firebaseapp.com",
  databaseURL: "https://video-app-fdc27.firebaseio.com",
  projectId: "video-app-fdc27",
  storageBucket: "video-app-fdc27.appspot.com",
  messagingSenderId: "886912511306",
  appId: "1:886912511306:web:5bbc36080812e626dda5b3",
  measurementId: "G-DYT0XMKDCC"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const db = firebase.database();
const auth = firebase.auth();

export {
  db,
  auth,
  firebase,
  googleAuthProvider,
  database as default 
};



// import * as firebase from 'firebase';

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
// };

// firebase.initializeApp(config);

// const database = firebase.database();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// export { firebase, googleAuthProvider, database as default };
