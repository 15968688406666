import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { firebase } from "./firebase/firebase";
import configureStore from "./store/configureStore";
import { login } from "./actions/auth";
import { startUserProps } from "./actions/user";
import App from "./components/app";
import { fetchLessons } from "./actions/lessons";
import { fetchFamilies } from "./actions/families";
import "normalize.css/normalize.css";
import LoadingPage from "./components/LoadingPage";

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

let hasRendered = false;

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById("root"));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(login(user.uid));
    store.dispatch(startUserProps(user));
    store.dispatch(fetchFamilies());
    renderApp();
    if (this.props.history.location.pathname === "/dashboard") {
      this.props.history.push("/dashboard");
    }
  } else {
    // store.dispatch(logout());
    renderApp();
    this.props.history.push("/");
  }
});
