import database from "../firebase/firebase";
import firebase from "firebase";
import _ from "lodash";

// const profile = 'https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/profile_images%2FQcroUjlQ16PvL2rUzmFKvBKCfuH3%2Fjessie_small.jpg?alt=media&token=58f28991-79da-493a-8c61-bd3af5baa238'
/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_LESSONS = "SET_LESSONS";
export const FETCH_TIMELINE_LESSONS = "FETCH_TIMELINE_LESSONS";
export const FETCH_PROFILE_LESSONS = "FETCH_PROFILE_LESSONS";
export const FETCH_MORE_LESSONS = "FETCH_MORE_LESSONS";
export const ADD_LESSON = "ADD_LESSON";
export const REMOVE_LESSON = "REMOVE_LESSON";
export const EDIT_LESSON = "EDIT_LESSON";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */

// SET LISTS
export const setLists = (lessons) => ({
  type: FETCH_TIMELINE_LESSONS,
  lessons,
});

export const fetchLessons = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    // return database.ref(`/timeline/${uid}`).once("value").then(snapshot => {
    return database
      .ref(`/lessons`)
      .once("value")
      .then((snapshot) => {
        const lessons = [];

        snapshot.forEach((childSnapshot) => {
          lessons.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        // console.log(lessons, "TEST LESSONS")
        dispatch(setLists(_.reverse(lessons)));
      });
  };
};

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */

// GETS FRIEND OR PUBLIC PROFILE BY PASSING USERS UID // USED by publicProfile
export function fetchUserProfileLists(uid) {
  return (dispatch) => {
    firebase
      .database()
      .ref(`users/${uid}/`)
      .on("value", (snapshot) => {
        let payload = snapshot.val();
        dispatch({
          type: FETCH_PROFILE_LESSONS,
          payload,
        });
      });
  };
}

// ADD_LESSON
export const addLesson = (lesson) => ({
  type: ADD_LESSON,
  lesson,
});

export const startAddLesson = (lessonData = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    // const newListKey = firebase.database().ref().child("lessons").push().key;

    const {
      createdAt = 0,
      uid = firebase.auth().currentUser.uid,
      id = "",
      class_level = "",
      photoURL = firebase.auth().currentUser.photoURL,
      displayName = firebase.auth().currentUser.displayName,
      slides = 0,
      notes = "",
      teacher_name = firebase.auth().currentUser.displayName,
      class_name = "",
      current_slide = 0,
    } = lessonData;

    const lesson = {
      id,
      uid,
      createdAt,
      class_name,
      current_slide,
      class_level,
      photoURL,
      slides,
      teacher_name,
      notes,
      displayName,
    };

    let updates = {};

    updates[`/lessons/${id}`] = lesson;
    updates[`/users/${currentUser.displayName}/lessons/${id}`] = lesson;
    updates[`/timeline/${currentUser.uid}/${id}`] = lesson;

    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addLesson({
            id: ref,
            ...lesson,
          })
        );
      });
  };
};

// ADD_ITEM
export const addItem = (id, item) => ({
  type: "ADD_ITEM",
  item,
  id,
});

export const startAddItem = (id, itemData = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch, getState) => {
    const newItemId = firebase
      .database()
      .ref(`/users/${currentUser.uid}/lessons/`)
      .child("items")
      .push().key;

    const {
      content = "",
      itemId = newItemId,
      createdAt = 0,
      listId = id,
    } = itemData;

    const item = { content, itemId, listId, createdAt };

    database
      .ref(`users/${currentUser.uid}/lessons/${id}/items`)
      .update(item)
      .then((ref) => {
        dispatch(
          addItem({
            id: ref.key,
            ...item,
          })
        );
      });
  };
};

// REMOVE_LESSON
export const removeList = ({ id } = {}) => ({
  type: REMOVE_LESSON,
  id,
});

export const startRemoveList = ({ id } = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    return database
      .ref(`users/${currentUser.displayName}/lessons/${id}`)
      .remove()
      .then(() => {
        dispatch(removeList({ id }));
      })
      .then(() => {
        const followersRef = database.ref(`followers/${currentUser.uid}`);
        followersRef.on("value", (snapshot) => {
          const followers = Object.keys(snapshot.val());
          followers.forEach((key) =>
            database
              .ref(`/timeline/${key}/${id}`)
              .remove()
              .then(() => {
                dispatch(removeList({ id }));
              })
          );
        });
      });
  };
};

// EDIT_LESSON
export const editLesson = (id, updates) => ({
  type: EDIT_LESSON,
  id,
  updates,
});

export const startEditLesson = (id, updates) => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    // return database.ref(`followers/${user.uid}`).once("value", snapshot => {
    // Read followers keys and turn into hash
    // const followers = Object.keys(snapshot.val())
    // Create object to send updated to
    let updates_ = {};
    // Loop through followers and send updates
    // followers.forEach(key => updates_[`/timeline/${key}/${id}`] = updates)
    // Sendd updates to Users own Lists
    // updates_[`users/${user.displayName}/lessons/${id}`] = updates
    // Send updates to Lists node
    updates_[`/lessons/${id}`] = updates;

    // updates_[`/timeline/${user.uid}/${id}`] = updates
    return database
      .ref()
      .update(updates_)
      .then(() => {
        dispatch(editLesson(id, updates));
      });

    // })
  };
};
