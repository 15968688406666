import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import firebase from "firebase";
import database from "../../firebase/firebase";
import FileUploader from "react-firebase-file-uploader";
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

var levels = [
  { class_level: 'Select level', state_code: 'DEFAULT' },
  { class_level: 'Upper Level', state_code: 'UL' },
  { class_level: 'Lower Level', state_code: 'LL' },
  { class_level: 'Beginner Class', state_code: 'BC' },
  { class_level: 'Pre-K', state_code: 'PK' },
  { class_level: 'Other', state_code: 'OTHER' }
];

const slides_urls_temp = [
{id: '23423erw', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2FIntro%402x.png?alt=media&token=2e6cfdd2-9c23-4a64-b120-9bab783401a2"},
{id: '23423ere', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F1-Review.jpg?alt=media&token=e35fa7ae-888e-493a-9b6c-a5cc1caeac5a"},
{id: '23423ertet', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F2-odd_1.jpg?alt=media&token=ca2ca9e3-f643-41f5-865e-3a10b45ef4fb"},
{id: '2342dfgd', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F3-odd_2.jpg?alt=media&token=c7f1e161-8936-4e45-a3af-fb69a5fce062"},
{id: '23423teryt', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F4-odd_3.jpg?alt=media&token=de924b4a-6930-49ca-811f-19d1a4a75c13"},
{id: '23423erdfgdf', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F5-odd_4.jpg?alt=media&token=0b418c25-b180-4fcc-a7ec-3398919bfa43"},
{id: '23423esdfsf', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F6-Vocab.jpg?alt=media&token=b2968783-7f2e-4a0d-959d-17a3b0408e54"},
{id: '23423edfdfg', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F7-VocabPractice.jpg?alt=media&token=84378a07-4f05-469e-bd9a-f7a0dcaa2fc0"},
{id: '23423efghfgh', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F8-VocabPractice2.jpg?alt=media&token=c01f0054-5687-4959-b896-9cea1adc857d"},
{id: '23423etyutgh', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F9-Activity.jpg?alt=media&token=f154098b-b127-4ef7-a19e-0598376a96a0"},
{id: '23423hjkh', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F10-Activity2.jpg?alt=media&token=fbe80888-ea84-4279-810c-5704827f86e2"},
{id: '23423erhjkugh', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F11-Activity3.jpg?alt=media&token=92e153bd-7f45-4d6d-841d-9ba3cbc30887"},
{id: '2342vbngf', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F12-Phonics2.jpg?alt=media&token=ad343b3b-730a-4d9d-9d51-4cb774dce100"},
{id: '23423vbnh', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F13-Read.jpg?alt=media&token=ada01f4d-2c9c-4291-8724-afce28ca55b5"},
{id: '234yuiyj', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F14-Read2.jpg?alt=media&token=417d36ec-8dfe-4102-bf86-d3ee0ccf0b33"},
{id: '2342cvnvbmb', img:"https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2Fclass_03%2F15-Phonics1.jpg?alt=media&token=6f2ff417-9936-4f6f-8cd6-4350d72390a1"}
]

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : "white",
  padding: grid,
  width: 250,
});


class LessonEditForm extends React.Component {

    fileObj = [];
    fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      displayName: this.props.user.displayName,
      teacher_name: this.props.user.displayName,
      photoURL: this.props.user.photoURL,
      class_name: props.lesson ? props.lesson.class_name : '',
      class_level: props.lesson ? props.lesson.class_level : '',
      notes: props.lesson ? props.lesson.notes : '',
      createdAt: props.lesson ? moment(props.lesson.createdAt) : moment(),
      id: props.lesson? props.lesson.id : '',
      uid: firebase.auth().currentUser.uid,
      levels: levels,
      file: [null],
      files: [],
      filenames: [null],
      // downloadURLs: slides_urls_temp,
      downloadURLs: props.lesson ? props.lesson.slides: [null],
      isUploading: false,
      uploadProgress: 0,
      error: ''
    };
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onClassNameChange = (e) => {
    const class_name = e.target.value;
    this.setState(() => ({ class_name }));
  };

  onLevelChange = (e) => {
    const class_level = e.target.value;
    this.setState(() => ({ class_level }));
  };

  // removeItem(note) {
  //   const { id } = this.props.lesson
  //   const updatedNotesCount = this.state.notes.filter(notes => {
  //     return notes !== note
  //   })
  //   this.setState({
  //     notes: [...updatedNotesCount]
  //   })
  //   const notes = this.state.notes
  //   database.ref(`/records/${id}`).update({notes})
  // }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const downloadURLs = reorder(
      this.state.downloadURLs,
      result.source.index,
      result.destination.index
    );

    this.setState({
      downloadURLs,
    });

  }


  onSubmit = (e) => {
    e.preventDefault();
    const slidesOBJ = _.toArray(_.toPlainObject(this.state.downloadURLs))
    
    if (!this.state.class_name) {
      this.setState(() => ({ error: 'Please provide lesson name.' }));
    } else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit({
        teacher_name: this.props.user.displayName,
        displayName: this.props.user.displayName,
        class_name: this.state.class_name,
        class_level: this.state.class_level,
        notes: this.state.notes,
        createdAt: this.state.createdAt.valueOf(),
        slides: slidesOBJ,
        class_level: this.state.class_level,
        uid: this.state.uid,
        id: this.state.id,
        photoURL: this.props.user.photoURL
      });
    }
  };

  uploadMultipleFiles(e) {
    this.fileObj.push(e.target.files)
    for (let i = 0; i < this.fileObj[0].length; i++) {
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    }
    this.setState({ file: this.fileArray })
  }


// UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });
 
  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });
 
  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };
 
  handleUploadSuccess = async filename => {
    const downloadURL = await firebase
      .storage()
      .ref(`classes/${this.state.id}/`)
      .child(filename)
      .getDownloadURL();
 
      const imageObj ={id: firebase.database().ref().push().key, img: downloadURL }

    this.setState((oldState) => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: _.without([...oldState.downloadURLs, imageObj], null),
      uploadProgress: 100,
      isUploading: false
    }));
  };

  // UPLOAD CODE

  render() {
    const slides = this.state.downloadURLs
    // console.log(slides, "ARRAY")
    // const images = _.toArray(slides)
    // console.log(images, "ARRAY")
    // const imgs = Object.values(images)
    // console.log(imgs, "ARRAY")

    return (
      <div className="container-fluid">
      <form onSubmit={this.onSubmit}>
        {this.state.error && <p className="form__error">{this.state.error}</p>}
        
        <div className="col-md-9">
        <div className="row">
        

        <div className="form-group">
          <div className='col-md-6 col-sm-6 col-xs-12'>
          <h4>Add Lesson</h4>
          <br/>
            <div style={{ width: '100%'}}>
              <input
                style={{ height: 45, width: '100%', borderRadius: 12, paddingLeft: 8, backgroundColor: '#eee' }}
                type="text"
                placeholder="Lessons title"
                value={this.state.class_name}
                onChange={this.onClassNameChange} />
            </div>
            
            <div className="input-group input-group-lg" style={{marginTop: 12}}>
              <div className="form-group">
                <select className="select-css" value={this.state.class_level} onChange={this.onLevelChange}>
                  {this.state.levels.map(item => (
                    <option key={item.state_code} value={item.class_level}>
                    {item.class_level}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div>
              <button type="button" onClick={this.onSubmit} className="btn btn-primary btn-lg">Post lesson</button>
            </div>


            <FileUploader
            accept="image/*"
            name="image-uploader-multiple"
            storageRef={firebase.storage().ref(`classes/${this.state.id}/`)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            multiple
          />
  
          <p>Progress: {this.state.uploadProgress}</p>


          </div> 

        </div>
        </div>

        <div>
            {slides.map((downloadURL, i) => (
               <div key={i} className="col-md-4">
                <img src={downloadURL.img} className="img-responsive" />
               </div>
            ))}
          </div>

        </div>
        
        <div className="col-md-3">
          <h4>Order Slides | Drag and Drop</h4>
          <div style={{paddingTop: 16}}>
          <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {slides.map((downloadURL, index) => {
                        // let dl_cleanup = downloadURL.replace('https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/classes%2F', "");
                        // let charCleanImageFormatJpg = '.jpg'
                        // let charCleanImageFormatPng = '.png'
                        // dl_cleanup = dl_cleanup.substring(0, dl_cleanup.indexOf(charCleanImageFormatJpg));
                        
                      return(
                      <Draggable key={downloadURL.id} draggableId={downloadURL.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div key={index} style={{maxHeight: 80, overflow: 'hidden'}}>
                              <img src={downloadURL.img} className="img-responsive" />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )})}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>


      </form>

      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, undefined)(LessonEditForm);